import styled from 'styled-components';
import { width } from '../../../constants';

export const Wrapper = styled.div<{ disabled?: boolean }>`
    position: relative;
    outline: none;
    cursor: pointer;
    ${({ disabled }) => (disabled ? 'pointer-events: none;' : null)}
`;

export const IconWrapper = styled.div`
    position: absolute;
    height: 100%;
    right: 0;
    top: 20px;
    padding: 0 7px;
    pointer-events: none;
    display: flex;

    @media (max-width: ${width.tablet}) {
        padding: 0 9px;
        top: 15px;
    }
`;

export const ManualButton = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    align-items: center;
    color: var(--color-D60);

    img {
        filter: grayscale(1);
    }
`;
