import { ObjectShape, OptionalObjectSchema } from 'yup/lib/object';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import yup from '../validations';
import { sessionStorage } from '../storage';
import { FORM_FIELDS, LOCAL_FORM, LOCAL_STORAGE_KEY } from '../../constants';
import { pick, removeUndefined } from '..';

export const encodeDataToB64 = (data: Record<string, any>): string => {
    try {
        return btoa(encodeURIComponent(JSON.stringify(data)));
    } catch (error) {
        return '';
    }
};

type DecodeDataFunc = <T>(dataStr: string) => T | null;
export const decodeDataFromB64: DecodeDataFunc = (dataStr) => {
    try {
        return JSON.parse(decodeURIComponent(atob(dataStr)));
    } catch (error) {
        return null;
    }
};

export const validateFields = async (schema: OptionalObjectSchema<any>, fieldValues: FieldValues): Promise<boolean> => {
    const fields = Object.keys(fieldValues);
    const customSchema = yup.object(
        fields.reduce((acc, key) => {
            if (schema.fields[key]) {
                acc[key] = schema.fields[key];
            }
            return acc;
        }, {} as ObjectShape),
    );
    try {
        await customSchema.validate(fieldValues);
        return true;
    } catch (e) {
        return false;
    }
};

const unauthorizedFieldsList = [
    FORM_FIELDS.AGREEMENT,
    FORM_FIELDS.MONTHLY_SALARY,
    FORM_FIELDS.AMOUNT,
    FORM_FIELDS.FIRST_NAME,
    FORM_FIELDS.LAST_NAME,
    FORM_FIELDS.MIDDLE_NAME,
    FORM_FIELDS.GENDER,
    FORM_FIELDS.EMAIL,
    FORM_FIELDS.PURPOSE,
    FORM_FIELDS.INITIAL_FEE,
    FORM_FIELDS.AUTO_BRAND,
    FORM_FIELDS.AUTO_BRAND_ID,
    FORM_FIELDS.AUTO_MODEL,
    FORM_FIELDS.AUTO_MODEL_ID,
    FORM_FIELDS.AUTO_YEAR,
];

export const saveUnauthorizedForm = (data: FE.FormData) => {
    const fields = removeUndefined(pick(data, unauthorizedFieldsList), ['']);
    sessionStorage.setItem(LOCAL_STORAGE_KEY, encodeDataToB64(fields));
};

export const loadUnauthorizedForm = (): FE.FormData | null => {
    const raw = sessionStorage.getItem(LOCAL_STORAGE_KEY);
    if (!raw) {
        return null;
    }

    try {
        return decodeDataFromB64<FE.FormData>(raw);
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const clearUnauthorizedForm = () => {
    sessionStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const getValidFields = (form: UseFormReturn) => {
    const values = form.getValues();
    const errors = Object.keys(values).reduce<Set<string>>((acc, key) => {
        const error = form.getFieldState(key)?.error;
        if (error) {
            acc.add(key);
        }
        return acc;
    }, new Set());
    return Array.from(errors.values()).reduce<typeof values>((accumulator, current) => {
        const { [current]: _, ...picked } = accumulator;
        return picked;
    }, values);
};

export const onlyLocalFieldsList = [
    FORM_FIELDS.WHEN_MONEY_NEEDED,
    FORM_FIELDS.HAS_RECENT_APPLICATION,
    FORM_FIELDS.RECENT_APPLICATION_CAUSE,
];

/** Сохраняет поля в локальное хранилище всегда */
export const saveLocalForm = (data: FE.FormData) => {
    const fields = removeUndefined(pick(data, onlyLocalFieldsList), ['']);
    sessionStorage.setItem(LOCAL_FORM, encodeDataToB64(fields));
};

export const loadLocalForm = (): FE.FormData | null => {
    const raw = sessionStorage.getItem(LOCAL_FORM);
    if (!raw) {
        return null;
    }

    try {
        return decodeDataFromB64<FE.FormData>(raw);
    } catch (e) {
        console.error(e);
        return null;
    }
};
