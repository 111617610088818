import styled, { keyframes } from 'styled-components';
import { Menu } from '@sravni/react-design-system';

const slideDownAnimation = keyframes`
  from {
    max-height: 0;
  }

  to {
    max-height: 250px;
  }
`;

export const MainItem = styled.p<{}>`
    position: relative;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1.63;
    overflow: hidden;
    cursor: pointer;
`;

export const SubitemItem = styled.p<{}>`
    position: relative;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 1.5;
    overflow: hidden;
    cursor: pointer;
`;

export const Wrapper = styled(Menu)<{ disabled?: boolean }>`
    position: absolute;
    top: 60px;
    width: 100%;
    height: auto;
    min-height: 1px;
    max-height: 280px;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y: auto;
    background-color: var(--color-L100);
    animation: ${slideDownAnimation} 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
`;
