import styled, { keyframes } from 'styled-components';
import { width } from '../../../constants';

const rotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const NoteIcon = styled.svg<{ size?: 'normal' | 'large' }>`
    position: relative;
    display: block;
    width: ${({ size = 'normal' }) => (size === 'normal' ? '20px' : '50px')};
    min-width: 20px;
    height: ${({ size = 'normal' }) => (size === 'normal' ? '20px' : '50px')};
    margin: 0 7px 0 0;

    &[data-rotation='true'] {
        animation: ${rotation} 1s linear infinite;
    }

    @media (max-width: ${width.tablet}) {
        width: 30px;
        min-width: 30px;
        height: 30px;
        margin: 0 0 0 10px;
    }
`;
