import React, { useState, useRef } from 'react';

import { Suggest } from '.';
import { ISuggestProps } from './types';

interface SuggestOptions {
    name: string;
    value: string;
}

type INameSuggestProps<T> = Pick<
    ISuggestProps<T>,
    'input' | 'onBlur' | 'onChange' | 'onFocus' | 'onSelect' | 'processing' | 'onlyList'
> & {
    options: Array<SuggestOptions>;
    emptyValue?: string;
};

export const anyOptionValue = 'Любая';

function useOptions(value: string, options: Array<SuggestOptions>, focused: boolean, emptyValue?: string) {
    if (!focused) {
        return [];
    }

    const items = options
        .filter((o) => !value || o.name.toLowerCase().includes(value.toLowerCase()))
        .map(({ name, value: _value }) => ({ name: _value, value: name }));

    if (emptyValue) {
        items.unshift({
            name: emptyValue,
            value: emptyValue,
        });
    }

    return items;
}

export const FormAutoSuggest = (props: INameSuggestProps<SuggestOptions> & { className?: string }) => {
    const [focused, setFocused] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const value = props?.input?.value === props.emptyValue ? '' : props?.input?.value || '';
    const options = useOptions(String(value), props.options, focused, props.emptyValue);
    const { onlyList = true } = props;

    const handlers = {
        onChange: props.onChange,
        onSelect: props.onSelect,
        onBlur: (e: any) => {
            props.onBlur && props.onBlur(e);
            if (props.onSelect && props.emptyValue && e.target?.value === '') {
                props.onSelect({ name: props.emptyValue, value: props.emptyValue });
            }
        },
        onFocus: (e: any) => {
            setFocused(true);
            props.onFocus && props.onFocus(e);
        },
    };

    const inputProps = {
        ...props.input,
        ...(props.input && !props.input.value && props.emptyValue && !focused ? { value: props.emptyValue } : {}),
    };

    return (
        <Suggest
            menuWithoutValue
            onlyList={onlyList}
            {...handlers}
            options={options}
            showEmpty={false}
            input={inputProps}
            className={props.className}
            wrapperRef={wrapperRef}
            processing={props.processing}
        />
    );
};
