import * as yup from 'yup';
import BaseSchema from 'yup/lib/schema';
import { Maybe, AnyObject } from 'yup/lib/types';

export type StoredValues = Record<
    string,
    { valid?: unknown[]; notValid?: { value: unknown; error: yup.ValidationError }[] }
>;

yup.setLocale({
    mixed: {
        required: 'Обязательное поле',
        default: 'Некорректное значение',
        notType: 'Некорректное значение',
        oneOf: 'Должно быть значение из списка',
    },
    string: {
        email: 'Некорректный email',
        max: 'Значение слишком длинное',
        min: 'Значение слишком короткое',
    },
});

/** Пропускает валидацию, если поле не изменялось с прошлого раза */
yup.addMethod(yup.mixed, 'skipIfNotChanged', function (storedValues: StoredValues = {}, chain: BaseSchema) {
    return this.test(function (value, context) {
        const { path, createError } = context;
        const { valid: storedValidValues, notValid: storedNotValidValues } = storedValues[path] || {
            valid: [],
            notValid: [],
        };
        const storedValid = (storedValidValues || []).find((v) => v === value);
        const storedNotValid = (storedNotValidValues || []).find((item) => item.value === value);

        if (storedValid === value && value !== undefined) {
            return true;
        }

        if (storedNotValid && storedNotValid.value === value) {
            return createError({
                path,
                message: storedNotValid.error.message,
            });
        }

        if (value === undefined) {
            return false;
        }

        return chain
            .validate(value, { context })
            .then((resValue) => {
                storedValues[path] = {
                    ...(storedValues[path] || {}),
                    valid: [...(storedValidValues || []).slice(-4), resValue],
                };
                return true;
            })
            .catch((error) => {
                storedValues[path] = {
                    ...(storedValues[path] || {}),
                    notValid: [...(storedNotValidValues || []).slice(-4), { error, value }],
                };
                return createError({
                    path,
                    message: error.message,
                });
            });
    });
});

yup.addMethod(yup.string, 'validateEmail', function validateEmail(message?: string) {
    return this.matches(
        /^(?=((?:(?:[A-Za-z0-9!#$%&'*+=?^_`{|}~-][.]?(?=([A-Za-z0-9!#$%&'*+=?^_`{|}~-]))*)*[^\s.]|"[A-Za-z0-9!@#$%&'*+=?^_`{|}~.\-\s]*")@{1}))\1(?:(?:([A-Za-z0-9][-]?(?=[A-Za-z0-9]*))*[A-Za-z0-9]+\.)+[A-Za-z]{2,})$/gm,
        {
            message: message || 'Некорректный email',
            name: 'email',
            excludeEmptyString: true,
        },
    );
});

yup.addMethod(yup.string, 'notRequiredNumber', function notRequiredNumber(message?: string) {
    return this.test('notRequiredNumber', message || 'Некорректное значение', function (value) {
        return value === undefined || value === '' || !isNaN(parseFloat(value || ''));
    });
});

yup.addMethod(yup.string, 'fullName', function fullName(message?: string) {
    return this.test('fullName', message || 'Введите ФИО полностью', function (value) {
        return (
            value === undefined ||
            value === '' ||
            /^[а-яё-]{2,}\s[а-яё-]{2,}\s[а-яё-]{2,}(\s[а-яё-]{2,})?$/i.test(value.trim())
        );
    });
});

yup.addMethod(yup.string, 'mobilePhone', function mobilePhone(message?: string) {
    return this.test('mobilePhone', message || 'Некорректный номер', function (value) {
        return (
            value === undefined ||
            value === '' ||
            (value.replace(/\D/g, '').length === 11 && /7([345689]{1})[\d]{9}/.test(value.replace(/\D/g, '')))
        );
    });
});

declare module 'yup' {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType,
    > extends yup.BaseSchema<TType, TContext, TOut> {
        skipIfNotChanged(storedValues: StoredValues, chain: BaseSchema): StringSchema<TType, TContext>;
        validateEmail(message?: string): StringSchema<TType, TContext>;
        notRequiredNumber(message?: string): StringSchema<TType, TContext>;
        fullName(message?: string): StringSchema<TType, TContext>;
        mobilePhone(message?: string): StringSchema<TType, TContext>;
    }
}

export default yup;
