import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { IGlobalState } from '..';
import { pick } from '../../helpers';
import { OrganizationChoiceType } from '../../constants';
import { isValid } from 'date-fns';

export const useFormStore = () => useSelector((state: IGlobalState): FE.FormStore => state.formStore);

export const useOrganizationChoiceType = () =>
    useSelector((state: IGlobalState): OrganizationChoiceType => state.formStore.organizationChoiceType);

export const useFormData = () => useSelector((state: IGlobalState): FE.FormData => state.formStore.formData);

export const useFormProfileMetaData = () =>
    useSelector((state: IGlobalState): IGlobalState['formStore']['profileMetaData'] => state.formStore.profileMetaData);

export const useFormFields = (fields: Array<string>) =>
    useSelector((state: IGlobalState) => {
        const values = pick(state.formStore.formData, fields);
        return fields.map((field) => values[field]);
    }, isEqual);

export const usePassportStepOptions = () =>
    useSelector(
        (state: IGlobalState): IGlobalState['formStore']['stepsFieldsOptions']['passportStep'] =>
            state.formStore.stepsFieldsOptions.passportStep,
    );
export const useWorkStepOptions = () =>
    useSelector(
        (state: IGlobalState): IGlobalState['formStore']['stepsFieldsOptions']['workStep'] =>
            state.formStore.stepsFieldsOptions.workStep,
    );

export const useProfileDataUpdatedDate = () =>
    useSelector((state: IGlobalState): Date | null => {
        const updated = state.formStore.profileMetaData?.selectedPeopleUpdated;
        if (!updated) {
            return null;
        }
        const updatedDate = new Date(updated);
        return isValid(updatedDate) ? updatedDate : null;
    });
