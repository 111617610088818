import { parse, isValid as dateIsValid, toDate } from 'date-fns';
import { ValidationError } from 'yup';
import { checkSnilsOnlyChecksum } from 'ru-validation-codes';
import { api } from '../client';
import { AUTO_NUMBER_REG_EXP, FORM_FIELDS } from '../../constants';
import { dateStringToTimestamp, parseFromHumanToIsoDate } from '..';
import yup from './validations';
import { checkLivingAddress, checkInnBySum, validatePassportDate, checkWorkAddress, checkRegion } from './simple';

const passports = new Map<string, boolean>();

export const isDateString =
    (formatString = 'dd.MM.yyyy'): yup.TestFunction =>
    (value, context) => {
        const asDate = parse(String(value), formatString, new Date());
        return dateIsValid(asDate) || new yup.ValidationError('Некорректная дата', value, context.path);
    };

export const isPassportNumber: yup.TestFunction = async (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const number = String(value).replace(/\D/g, '');
    const valid = number.length === 10;
    return valid || new ValidationError('Укажите корректный номер', value, context.path);
};

/**
 * 3ья и 4ая цифры серии паспорта соответствуют последним двум цифрам года выпуска бланка паспорта (отклонение на 1-3 года).
 * Пример: паспорт серии 45 04 выдан в 2004 году, а паспорт серии 37 11 выдан в 2011 году.
 * */
export const isPassportSeriesValid: yup.TestFunction = async (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const date = String(context.parent[FORM_FIELDS.PASSPORT_DATE]);
    const passportSN = String(value).replace(/\D/g, '');
    if (passportSN.length !== 10) {
        return new ValidationError('Укажите корректный номер', value, context.path);
    }

    const passportDate = parseFromHumanToIsoDate(date);
    const passportYear = Number(passportSN.slice(2, 4));
    const possibleYears = [2000 + passportYear, 1900 + passportYear];

    const isValid =
        passportDate &&
        possibleYears.some((possibleYear) => {
            const passportDateYear = passportDate.getFullYear();
            return possibleYear - 3 <= passportDateYear && passportDateYear <= possibleYear + 3;
        });

    return isValid || new ValidationError('Укажите корректный номер', value, context.path);
};

export const isActivePassport: yup.TestFunction = async (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const passportSN = String(value).replace(/\D/g, '');
    if (passportSN.length !== 10) {
        return new ValidationError('Укажите корректный номер', value, context.path);
    }

    if (!passports.has(passportSN)) {
        passports.set(passportSN, await api.checkPassport(passportSN));
    }
    return Boolean(passports.get(passportSN)) || new ValidationError('Паспорт недействителен', value, context.path);
};

export const isPassportDateCorrect =
    (birthdayFieldName: string): yup.TestFunction =>
    (value, context) => {
        let valid = false;
        if (value) {
            const birthDate = toDate(dateStringToTimestamp(String(context.parent[birthdayFieldName])));
            const passportIssueDate = toDate(dateStringToTimestamp(String(value)));

            valid = validatePassportDate(birthDate, passportIssueDate);
        }
        return valid || new ValidationError('Паспорт недействителен', value, context.path);
    };

export const isCorrectUnitCode: yup.TestFunction = async (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const valid = /\d{3}-\d{3}/.test(String(value).trim());
    return valid || new ValidationError('Укажите корректный код', value, context.path);
};

export const isSnilsNumber: yup.TestFunction = (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const snils = String(value).replace(/\D/g, '');
    const valid = snils ? checkSnilsOnlyChecksum(snils) : true;
    return valid || new ValidationError('Введен неверный СНИЛС', value, context.path);
};

/** Подходит для проверки домашнего адреса */
export const isAddressHouseSpecified: yup.TestFunction = async (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const valid = await checkLivingAddress(String(value));

    return valid || new ValidationError('Укажите корректные данные', value, context.path);
};

/** Подходит для проверки рабочего адреса */
export const isAddressHasCorrectStructure: yup.TestFunction = async (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const valid = await checkWorkAddress(String(value));

    return valid || new ValidationError('Укажите корректные данные', value, context.path);
};

export const isRegionValid: yup.TestFunction = async (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const valid = await checkRegion(String(value));
    return valid || new ValidationError('Укажите корректный населенный пункт', value, context.path);
};

export const isNameValid: yup.TestFunction = (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const valid = value === null || (typeof value === 'string' && /^[а-яё'-]{2,}(\s[а-яё-]{2,})?$/i.test(value));
    return valid || new ValidationError('Укажите корректные ФИО', value, context.path);
};

export const isCorrectInn: yup.TestFunction = (value, testContext) => {
    const context = testContext.parent ? testContext : (testContext.options.context as yup.TestContext);
    const inn = String(value).replace(/\D/g, '');
    if (!inn) {
        return true;
    }
    if (![10, 12].includes(inn.length)) {
        return new ValidationError('Номер ИНН - 10 или 12 символов', value, context.path);
    }
    const valid = checkInnBySum(inn);
    return valid || new ValidationError('Недействительный ИНН организации', value, context.path);
};

export const testAutoNumber =
    (required = false): yup.TestFunction =>
    (value, context) => {
        const isValid = (!required && !value) || AUTO_NUMBER_REG_EXP.test(value as string);
        return isValid || new yup.ValidationError('Укажите корректный номер', value, context.path);
    };

export const dateIsEarlier =
    (date: Date, message?: string, formatString = 'dd.MM.yyyy'): yup.TestFunction =>
    (value, context) => {
        const asDate = parse(String(value), formatString, new Date());
        const isValid = !value || date >= asDate;
        return isValid || new yup.ValidationError(message || 'Некорректная дата', value, context.path);
    };

export const dateIsLater =
    (date: Date, message?: string, formatString = 'dd.MM.yyyy'): yup.TestFunction =>
    (value, context) => {
        const asDate = parse(String(value), formatString, new Date());
        const isValid = !value || date <= asDate;
        return isValid || new yup.ValidationError(message || 'Некорректная дата', value, context.path);
    };

export const dateIsLaterThanField =
    (field: string, message?: string, formatString = 'dd.MM.yyyy'): yup.TestFunction =>
    (value, context) => {
        const date = parse(context.parent[field], formatString, new Date());
        const asDate = parse(String(value), formatString, new Date());
        const isValid = !value || (dateIsValid(date) && date <= asDate);
        return isValid || new yup.ValidationError(message || 'Некорректная дата', value, context.path);
    };

export const differThanNumber =
    (fields: string | Array<string>, message?: string): yup.TestFunction =>
    (value, context) => {
        const fieldValues = ([] as Array<string>).concat(fields).map((field) => context.parent[field]);
        const normalizedValue = ((value as string) || '').replace(/\D/g, '');

        const isValid =
            !normalizedValue ||
            fieldValues.every((fieldValue) => normalizedValue !== ((fieldValue as string) || '').replace(/\D/g, ''));
        return isValid || new yup.ValidationError(message || 'Совпадает с другим полем', value, context.path);
    };

export const onlyRussianSymbols: yup.TestFunction = (value, context) => {
    const isValid = !value || /[а-яё\W]/gi.test(value as string);
    return isValid || new yup.ValidationError('Только кириллица', value, context.path);
};

export const russianNoFirstNumber: yup.TestFunction = (value, context) => {
    const isValid = !value || /^[а-яё][а-яё\-\d\s.,/]+/gi.test(value as string);
    return isValid || new yup.ValidationError('Только кириллица, не должно начинаться с цифры', value, context.path);
};

export const lastExperienceMonthIsCorrect: yup.TestFunction = (value, context) => {
    const lastExperienceYear = context.parent[FORM_FIELDS.LAST_EXPERIENCE_START_YEAR];
    if (!lastExperienceYear) {
        return true;
    }
    const asDate = parse(String(`01.${value}.${lastExperienceYear}`), 'dd.MM.yyyy', new Date());
    const isValid = !value || new Date() >= asDate;
    return isValid || new yup.ValidationError('Некорректное значение', value, context.path);
};
