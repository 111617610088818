import React, { useEffect, useRef } from 'react';
import { Menu } from '@sravni/react-design-system';
import { Wrapper } from './index.styles';
import { IOption, OptionRender } from '../types';

const NothingFun = () => null;

interface IMenuProps<T> {
    items: Array<IOption<T>>;
    selectItem: (item: T) => void;
    selected: number;
    render?: OptionRender<IOption<T>>;
    showEmpty?: boolean;
    emptyText?: string;
    prefixItem?: React.ReactNode;
}

export function MenuList<T>(props: IMenuProps<T>) {
    const defaultRender: OptionRender<IOption<T>> = (item) => String(item.value);
    const wrapperRef = useRef<HTMLUListElement>(null);

    const {
        items = [],
        render = defaultRender,
        selected,
        selectItem = NothingFun,
        showEmpty = true,
        emptyText = 'Ничего не найдено',
        prefixItem,
        ...otherProps
    } = props;

    const emptyItems = showEmpty && <Menu.Item disabled>{emptyText}</Menu.Item>;

    useEffect(() => {
        const { current: wrapper } = wrapperRef;
        if (!wrapper) {
            return;
        }
        const selectedElement = wrapper.childNodes[selected] as HTMLLIElement;
        if (!selectedElement) {
            return;
        }
        if (
            selectedElement.offsetTop + selectedElement.offsetHeight > wrapper.scrollTop + wrapper.offsetHeight ||
            selectedElement.offsetTop < wrapper.scrollTop
        ) {
            wrapper.scrollTo(0, selectedElement.offsetTop);
        }
    }, [selected, wrapperRef.current]);

    const itemComponents =
        items.length > 0
            ? items.map((item, i) => {
                  return (
                      <Menu.Item
                          key={String(i)}
                          onClick={() => !item.unselectable && item.option && selectItem(item.option)}
                          active={i === selected}>
                          {render(item)}
                      </Menu.Item>
                  );
              })
            : emptyItems;

    return (
        <Wrapper {...otherProps}>
            {prefixItem && <Menu.Item active={false}>{prefixItem}</Menu.Item>}
            {itemComponents}
        </Wrapper>
    );
}
