import React from 'react';

/**
 * Эффект, срабатывает при клике вне объекта.
 * Написан взамен @sravni/ui/multiSelect/useOutsideClick, так как при событии 'click'
 * функционал работал некорректно (срабатывал при попытке выделить текст и остановкой за пределом объекта ввода)
 */
export function useOutsideClick(ref: React.RefObject<HTMLElement>, callback: (event?: MouseEvent) => void) {
    const handleClick = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
            callback(e);
        }
    };

    return React.useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    });
}
