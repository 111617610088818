import React, { useState, useRef, useEffect, useCallback } from 'react';

import { Cross } from '@sravni/react-icons';
import { useOutsideClick } from './helpers/useOutsideClick';

import { Wrapper, IconWrapper, ManualButton } from './index.styles';
import { MenuList } from './menu';
import { mapEnToRu } from '../../../../helpers';
import { Icon } from '../icon';
import { ErrorTextWrapper } from '../input/errorTextWrapper';
import { IOption, ISuggestProps } from './types';
import { TextInput } from '../../../landing.common';

const processLabelTap = ({ target }: React.MouseEvent<HTMLElement>) => {
    if (target && (target as HTMLElement).tagName.toLowerCase() === 'label') {
        const inputs = ((target as HTMLElement).parentNode as HTMLElement).getElementsByTagName('input');
        if (inputs.length > 0) inputs[0].focus();
    }
};

const NothingFun: () => void = () => null;

const DefaultMapper: <T>(data: T) => IOption<T> = (data) => ({
    name: (data as unknown as IOption<unknown>).name,
    value: (data as unknown as IOption<unknown>).value,
    option: data,
});

/**
 * Логика проста, если передается хендлер onSelect, то считаем что саджест ожидает выбор из списка
 * Саджест работает с интерфейсом IOption<T>, где T это передаваемый формат options и IOption техгнические требования к работоспособности
 * Если T не имплементирует IOption, то DefaultMapper не подойдет, нужно передать уникальный маппер(З.Ы. смотри пример в AddressSuggest)
 */
export const Suggest = <T,>(props: ISuggestProps<T>) => {
    const {
        onChange = NothingFun as NonNullable<typeof props['onChange']>,
        onFocus = NothingFun as NonNullable<typeof props['onFocus']>,
        onBlur: onBlurDefault = NothingFun as NonNullable<typeof props['onBlur']>,
        onSelect = NothingFun as NonNullable<typeof props['onSelect']>,
        onClear = NothingFun as NonNullable<typeof props['onClear']>,
        onManual = NothingFun as NonNullable<typeof props['onManual']>,
        clearable = false,
        mapperOption = DefaultMapper,
        wrapperRef = useRef<HTMLDivElement | null>(null),
        options = [],
        input = { value: '' },
        menuWithoutValue = false,
        showEmpty = true,
        emptyText,
        optionRender,
        onlyRus = false,
        onlyList = false,
        processing = false,
        attention = false,
        showManualButton,
        className,
        customIcon,
    } = props;

    const { errorMessage, ...inputProps } = input;

    const [opened, setOpened] = useState<boolean>(false);
    const [selected, setSelected] = useState<number>(0);
    const [clickedOutSide, setClickedOutSide] = useState<boolean>(false);

    const blurRef = useRef(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const cursorPosition = useRef<number>(input.value ? input.value.length : 0);

    const items = options
        .filter((option) => ![null, undefined].includes(option as any))
        .map((option) => mapperOption(option));

    useOutsideClick(wrapperRef, () => setClickedOutSide(true));

    const open = () => {
        if (!opened) {
            setOpened(true);
        }
        setSelected(0);
    };

    const onBlur: typeof props['onBlur'] = (...args) => {
        blurRef.current = true;
        onBlurDefault(...args);
        const { current } = inputRef;
        if (current) {
            current.focus();
            current.blur();
        }
    };

    const selectItem = (item: T) => {
        setOpened(false);
        const { value } = mapperOption(item);
        const { name = '' } = input;
        const event = { target: { name, value } };
        cursorPosition.current = value.length;
        onChange(event);
        onSelect(item);
        onBlur(event, item);
    };

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const valueFromEvent = target.value;
        cursorPosition.current = target.selectionStart || 0;
        if (onlyRus) {
            const transpiled = mapEnToRu(valueFromEvent);
            if (transpiled !== valueFromEvent) {
                const { selectionStart } = target;
                target.value = transpiled;
                target.setSelectionRange(selectionStart || 0, selectionStart || 0);
            }
        }
        open();
        onChange(event);
    };

    const onClearHandler = () => {
        const { name = '' } = input;
        onBlur({ target: { name, value: '' } });
        onClear();
    };

    const onKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const { keyCode } = event;
            if (processing) {
                if ([9, 13, 38, 40].includes(keyCode)) {
                    event.preventDefault();
                }
            } else {
                /** key tab */
                if (keyCode === 9) {
                    setClickedOutSide(true);
                }
                /** key down */
                if (keyCode === 40) {
                    setSelected(Math.min(selected + 1, options.length - 1));
                    event.preventDefault();
                }
                /** key up */
                if (keyCode === 38) {
                    setSelected(Math.max(selected - 1, 0));
                    event.preventDefault();
                }
                /** key enter */
                if (keyCode === 13) {
                    selectItem(options[selected]);
                }
            }
        },
        [selected, processing, options.length],
    );

    const onFocusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        open();
        onFocus(e);
    };

    const onFocusWrapperHandler = (e: React.FocusEvent<HTMLDivElement>) => {
        if (inputRef.current && !opened) {
            inputRef.current.selectionStart = inputRef.current.selectionEnd = cursorPosition.current;
        }
    };

    /** Обход отлова клика на следующем рендере для собственной реализации onBlur */
    useEffect(() => {
        if (opened) {
            setOpened(false);
            const { name = '', value = '' } = input;

            const exact = items.find(({ value: itemValue = '', onlyManual }) => {
                return itemValue.trim().toLowerCase() === value.trim().toLowerCase() && !onlyManual;
            });

            const found = onlyList
                ? items.filter(({ value: itemValue = '', onlyManual }) => {
                      return itemValue.trim().toLowerCase().startsWith(value.trim().toLowerCase()) && !onlyManual;
                  })
                : [];

            const matched = exact || (found.length === 1 && found[0]);

            if (matched) {
                onChange({ target: { name, value: matched.value } });
                onSelect(matched.option);
                onBlur({ target: { name, value: matched.value } }, matched.option);
            } else if (onlyList) {
                onChange({ target: { name, value: '' } });
                onBlur({ target: { name, value: '' } });
            } else {
                onBlur({ target: { name, value } });
            }
        }
        setClickedOutSide(false);
    }, [clickedOutSide]);

    const manualButton =
        showManualButton && !processing ? (
            <ManualButton
                onClick={() => {
                    onManual();
                    setClickedOutSide(true);
                }}>
                <img alt="" loading="lazy" src={require('../../../../public/resources/selection/icons/edit.svg')} />
                Заполнить самостоятельно
            </ManualButton>
        ) : undefined;

    return (
        <Wrapper ref={wrapperRef} className={className} onFocus={onFocusWrapperHandler}>
            <ErrorTextWrapper onClick={processLabelTap} errorText={errorMessage}>
                <TextInput
                    {...inputProps}
                    ref={inputRef}
                    withoutErrorTextWrapper={true}
                    autoComplete="off"
                    onKeyDown={onKeyDown}
                    onChange={onChangeHandler as any}
                    onFocus={onFocusHandler}
                    onBlur={(e) => {
                        if (!blurRef.current) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        blurRef.current = false;
                    }}
                    errorMessage={errorMessage}
                    icon={
                        clearable && Boolean(inputProps.value) ? (
                            <Cross style={{ cursor: 'pointer' }} onClick={onClearHandler} />
                        ) : (
                            (!processing && customIcon) || null
                        )
                    }
                />
                {processing && (
                    <IconWrapper>
                        <Icon type="spinner" />
                    </IconWrapper>
                )}
                {attention && (
                    <IconWrapper>
                        <Icon type="attention" />
                    </IconWrapper>
                )}
            </ErrorTextWrapper>

            {opened && Boolean(menuWithoutValue || input?.value?.length) && (
                <MenuList
                    items={items}
                    selected={selected}
                    selectItem={selectItem}
                    render={optionRender}
                    showEmpty={showEmpty}
                    emptyText={emptyText}
                    prefixItem={manualButton}
                />
            )}
        </Wrapper>
    );
};

export type SuggestAsyncProps<T> = Omit<ISuggestProps<T>, 'options' | 'showEmpty'> & {
    getOptions: (value: string) => Promise<T[]>;
    filterOptions?: (options: T[]) => T[];
    delay?: number;
};

export function SuggestAsync<T = any>(props: SuggestAsyncProps<T>) {
    const {
        getOptions,
        filterOptions,
        delay = 1000,
        input: { value, ...input },
        onFocus = NothingFun,
        onBlur = NothingFun,
        onChange = NothingFun,
        menuWithoutValue,
        ...other
    } = props;

    const suggestValue = (value || '').trim();

    const [options, setOptions] = useState<T[]>([]);
    const [focused, setFocused] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cachedValue, setCachedValue] = useState(value);
    const [failed, setFailed] = useState(false);

    useEffect(() => {
        if ((suggestValue === cachedValue && options.length) || !focused) {
            return;
        }

        setFailed(false);
        const handler = setTimeout(
            async () => {
                try {
                    setLoading(true);
                    const newOptions =
                        (focused && (suggestValue || menuWithoutValue) && (await getOptions(suggestValue || ''))) ||
                        options;
                    setOptions(newOptions);
                    setCachedValue(suggestValue);
                } catch (e) {
                    setFailed(true);
                    throw e;
                } finally {
                    setLoading(false);
                }
            },
            suggestValue ? delay : 0,
        );

        return () => {
            setLoading(false);
            clearTimeout(handler);
        };
    }, [suggestValue, focused]);

    const onChangeHandler = useCallback(
        (event: FE.FieldChangeEvent) => {
            if (!focused) {
                setFocused(true);
            }
            onChange(event);
        },
        [onChange, focused],
    );

    const onInnerFocus = useCallback(
        (event: FE.FieldChangeEvent) => {
            setFocused(true);
            onFocus(event);
        },
        [onFocus],
    );

    const onInnerBlur = useCallback(
        (event: FE.FieldChangeEvent, item?: T) => {
            setFocused(false);
            onBlur(event, item, options);
        },
        [onBlur, options],
    );

    return (
        <Suggest
            {...other}
            input={{ value, ...input }}
            onFocus={onInnerFocus}
            onBlur={onInnerBlur}
            onChange={onChangeHandler}
            options={filterOptions ? filterOptions(options) : options}
            showEmpty={!loading && !options.length}
            processing={loading}
            attention={failed}
            menuWithoutValue={menuWithoutValue}
        />
    );
}
