import { addYears, startOfDay, subDays, subYears } from 'date-fns';
import { ADDRESS_POSTAL_CODE_REGEXP, ADDRESS_STREET_HOUSE_FIAS_LEVEL } from '../../../constants';
import { api, provider } from '../../client';
import { getStreetAndHouseFromAddress } from '../../addresses';

/** Отделяет от адреса второстепенные части, которые мешают запросу в дадата */
export const splitAddressToSuggest = (value?: string) => {
    const [main, second = ''] = (value || '').split(
        /,\s?((((зон(а)?|эт(аж)?|пом(ещ)?(ение)?|в\/ч|воинская|офис|контора)\s).*)|(промзона)$)/gi,
    );
    return [
        main.replace(/корпус/, 'к').replace(ADDRESS_POSTAL_CODE_REGEXP, ''),
        second.toLowerCase().replace(/\s[а-яё](?=[^а-яё])/gi, (a) => a.toUpperCase()),
    ];
};

/** Проверяет, валидна ли дата выдачи паспорта на день today */
export const validatePassportDate = (birthDate: Date, passportIssueDate: Date, today = startOfDay(new Date())) => {
    const AVAILABLE_CHANGE_DAYS = 90;
    let minAvailablePassportIssueDate: Date;

    if (birthDate >= subDays(subYears(today, 20), AVAILABLE_CHANGE_DAYS)) {
        /** если возраст менее 20 лет */
        minAvailablePassportIssueDate = addYears(new Date(birthDate), 14);
    } else if (birthDate >= subDays(subYears(today, 45), AVAILABLE_CHANGE_DAYS)) {
        /** если возраст более 20 лет, но менее 45 */
        minAvailablePassportIssueDate = addYears(new Date(birthDate), 20);
    } else {
        /** если возраст более 45 лет */
        minAvailablePassportIssueDate = addYears(new Date(birthDate), 45);
    }

    return passportIssueDate >= minAvailablePassportIssueDate;
};

export const checkInnBySum = (inn: string): boolean => {
    if (/[^0-9]/.test(inn)) {
        return false;
    }

    if ([10, 12].indexOf(inn.length) === -1) {
        return false;
    }

    const checkDigit = function (inn: string, coefficients: number[]) {
        let n = 0;
        coefficients.forEach((value, i) => {
            n += value * Number(inn[i]);
        });
        return (n % 11) % 10;
    };

    switch (inn.length) {
        case 10: {
            const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
            return n10 === parseInt(inn[9]);
        }
        case 12: {
            const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            return n11 === parseInt(inn[10]) && n12 === parseInt(inn[11]);
        }
        default: {
            return false;
        }
    }
};

const isAddressDataHasHouse = (address: string, data: Dadata.AddressSuggestItem['data']) => {
    const { house, street, city, settlement, fias_level } = data;
    const place = city || settlement;

    return (
        Boolean(street || place) &&
        Boolean(fias_level) &&
        (Boolean(house) || ADDRESS_STREET_HOUSE_FIAS_LEVEL.includes(String(fias_level)))
    );
};

const isMilitaryAddress = (addressPart: string, data: Dadata.AddressSuggestItem['data']) => {
    const { fias_level } = data;
    return addressPart && Number(fias_level) >= 4 && /((в\/ч|воинская)\s.+$)/gi.test(addressPart);
};

const isAddressDataHasTerritory = (address: string, data: Dadata.AddressSuggestItem['data']) => {
    const { fias_level, street_type, settlement_type } = data;
    const exceptions = ['тер', 'мкр', 'стр', 'г-к', 'пл-ка'];

    return (
        Number(fias_level) >= 6 &&
        (exceptions.includes(street_type || '') || exceptions.includes(settlement_type || ''))
    );
};

const alternativeCheck = (address: string, items: Array<Dadata.AddressSuggestItem>) => {
    if (!items.length) {
        return false;
    }

    const { data, value } = items[0];
    const { street: streetPart } = getStreetAndHouseFromAddress(value);

    if (items.every((item) => item.value.startsWith(streetPart))) {
        return isAddressDataHasHouse(address, data) || isAddressDataHasTerritory(address, data);
    }
};

export const checkLivingAddress = async (address: string) => {
    const [mainPart, secondPart] = splitAddressToSuggest(address);

    const items = mainPart ? await api.addressSuggest(mainPart, 'house') : [];

    const fit = items.find(({ value }) => value.toLowerCase() === mainPart.toLowerCase());
    return fit && (isAddressDataHasHouse(address, fit.data) || isMilitaryAddress(secondPart, fit.data));
};

export const checkWorkAddress = async (address: string) => {
    const [mainPart, secondPart] = splitAddressToSuggest(address);
    const items = mainPart ? await api.addressSuggest(mainPart, undefined, 1) : [];
    const fit =
        items.find(({ value }) => value.toLowerCase() === mainPart.toLowerCase()) || (items.length === 1 && items[0]);

    return (
        fit &&
        (isAddressDataHasHouse(address, fit.data) ||
            alternativeCheck(address, items) ||
            isMilitaryAddress(secondPart, fit.data))
    );
};

export const checkRegion = async (route?: string) => {
    if (!route) {
        return false;
    }

    const data = await provider.locationSearchByRoute(route);

    if (!data[0]) {
        return false;
    }

    return (
        data[0].route.split('.').filter((item) => !!item).length >= 3 || data[0].localityType.toLowerCase() === 'город'
    );
};
